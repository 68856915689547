import cx from 'classnames';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import Api from '../../service/Api';
import { getProp, __, createUrl } from '../../utilities/common';
import { setAlert } from './../../actions';
import Content from './../../component/content';
import DataTable from './../../component/dataTable';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import MainHeader from './../../component/mainHeader';
import SidePanel from './../../component/sidePanel';
import ProtectedContent from './../../component/protectedContent';
import FloatingActionButton from './../../component/floatingActionButton';
import LoadMoreBtn from './../../component/loadMoreBtn';
import PropTypes from 'prop-types';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.baseUrl = '/app/ota-updates';

    this.state = {
      loadOtaUpdatesLoader: false,
      otaUpdates: [],
      codeLists: [],
      filter: {
        country: this.props.location.query.country || '',
        lang: this.props.location.query.lang || '',
        ecrType: this.props.location.query.ecrType || '',
        cpuVersion: this.props.location.query.cpuVersion || '',
        icmVersion: this.props.location.query.icmVersion || '',
        ecrVersion: this.props.location.query.ecrVersion || '',
      },
      offset: parseInt(this.props.location.query.offset) || 0,
      limit: 100,
      nextOffset: null,
      loadedOnce: false,
      checked: new Set(),
    };
  }

  componentDidMount() {
    this.loadOtaUpdates();
  }

  componentWillReceiveProps(nextProps) {
    const oldState = this.state;
    const offset = parseInt(nextProps.location.query.offset) || 0;
    const country = nextProps.location.query.country || '';
    const lang = nextProps.location.query.lang || '';
    const ecrType = nextProps.location.query.ecrType || '';
    const cpuVersion = nextProps.location.query.cpuVersion || '';
    const icmVersion = nextProps.location.query.icmVersion || '';
    const ecrVersion = nextProps.location.query.ecrVersion || '';

    const newFilter = {
      country,
      lang,
      ecrType,
      cpuVersion,
      icmVersion,
      ecrVersion,
    };

    this.setState(
      {
        filter: newFilter,
        offset,
        checked:
          JSON.stringify(newFilter) !== JSON.stringify(oldState.filter)
            ? new Set()
            : oldState.checked,
      },
      () => {
        if (
          offset !== oldState.offset ||
          country !== oldState.filter.country ||
          lang !== oldState.filter.lang ||
          ecrType !== oldState.filter.ecrType ||
          cpuVersion !== oldState.filter.cpuVersion ||
          icmVersion !== oldState.filter.icmVersion ||
          ecrVersion !== oldState.filter.ecrVersion
        ) {
          this.loadOtaUpdates();
        }
      },
    );
  }

  loadOtaUpdates = (loadAll = false) => {
    const { otaUpdates, filter } = this.state;
    const { dispatch } = this.props;
    let { offset, limit, loadedOnce } = this.state;

    if (loadAll) {
      loadedOnce = false;
    }

    // nacitame zoznam updatov
    this.setState(
      {
        loadOtaUpdatesLoader: true,
      },
      () => {
        if (!loadedOnce) {
          limit = limit + offset;
          offset = 0;
        }

        let params = { limit, offset };

        if (getProp(filter, 'country')) {
          params.country = filter.country;
        }

        if (getProp(filter, 'ecrType')) {
          params.ecrtype = filter.ecrType;
        }

        if (getProp(filter, 'lang')) {
          params.lang = filter.lang;
        }

        if (getProp(filter, 'cpuVersion')) {
          params.cpuversion = filter.cpuVersion;
        }

        if (getProp(filter, 'ecrVersion')) {
          params.ecr_version = filter.ecrVersion;
        }

        if (getProp(filter, 'icmVersion')) {
          params.icm_version = filter.icmVersion;
        }

        Api.get('/admin/otaupdates', params)
          .then(response => {
            this.setState({
              loadOtaUpdatesLoader: false,
              otaUpdates:
                parseInt(offset) === 0
                  ? response.otaupdates
                  : otaUpdates.concat(response.otaupdates),
              codeLists: response.codelists,
              nextOffset: response.next_offset,
              loadedOnce: true,
            });
          })
          .catch(response => {
            dispatch(
              setAlert({
                type: 'error',
                title: __('Nebolo možné načítať zoznam aktualizácií'),
                onConfirm: () => dispatch(setAlert(null)),
              }),
            );

            this.setState({
              loadOtaUpdatesLoader: false,
            });
          });
      },
    );
  };

  onClickDeleteOtaUpdate = otaUpdate => {
    const { dispatch } = this.props;

    dispatch(
      setAlert({
        type: 'warning',
        title: __('Ste si istý?'),
        confirmButtonColor: '#DD6B55',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: __('Áno, zmazať'),
        cancelButtonText: __('Nie, zrušiť'),
        buttonsStyling: false,
        text: __("Naozaj chcete zmazať OTA aktualizáciu '%(name)s'?").format({
          name: getProp(otaUpdate, 'filename'),
        }),
        onConfirm: () => {
          this.deleteOtaUpdate(otaUpdate._id);
        },
        onCancel: () => dispatch(setAlert(null)),
      }),
    );
  };

  deleteOtaUpdate = otaUpdateId => {
    const { dispatch } = this.props;

    this.setState({
      loadOtaUpdatesLoader: true,
    });

    Api.delete(`/admin/otaupdates/${otaUpdateId}`)
      .then(response => {
        dispatch(
          setAlert({
            type: 'success',
            title: __('OTA aktualizácia bola zmazaná'),
            onConfirm: () => dispatch(setAlert(null)),
          }),
        );

        this.loadOtaUpdates();
      })
      .catch(error => {
        dispatch(
          setAlert({
            type: 'error',
            title: __('Pri mazaní OTA aktualizácie došlo k chybe'),
            onConfirm: () => dispatch(setAlert(null)),
          }),
        );

        this.setState({
          loadOtaUpdatesLoader: false,
        });
      });
  };

  multiOperation = (operation, confirmTitle) => {
    const { dispatch } = this.props;
    if (this.state.checked.size && window.confirm(confirmTitle)) {
      this.setState({
        loadOtaUpdatesLoader: true,
      });
      Api.post(
        '/admin/otaupdates-multi',
        JSON.stringify({ operation, ids: [...this.state.checked] }),
      )
        .then(() => {
          if (operation === 'delete') {
            this.setState({ checked: new Set() });
          }
          this.loadOtaUpdates();
        })
        .catch(error => {
          console.error(error);
          dispatch(
            setAlert({
              type: 'error',
              title: __('Pri hromadnej operácii došlo k chybe'),
              onConfirm: () => dispatch(setAlert(null)),
            }),
          );

          this.setState({
            loadOtaUpdatesLoader: false,
          });
        });
    }
  };

  render() {
    const {
      otaUpdates,
      codeLists,
      filter,
      offset,
      nextOffset,
      loadOtaUpdatesLoader,
    } = this.state;
    const { sidepanel } = this.props;

    const onSuccessSidePanel = () => {
      this.loadOtaUpdates(true);
    };

    return (
      <div id="content-wrapper">
        <MainHeader title={__('OTA aktualizácie')} progress={100} />
        <Content>
          <Loader show={loadOtaUpdatesLoader}>
            <div className="row">
              <div className="col-md-12">
                <Link
                  to={createUrl([this.baseUrl, 'filter'], {
                    ...filter,
                    offset,
                  })}
                  className="btn btn-primary-o pull-right"
                >
                  {__('Upraviť filter')}
                </Link>
                {!!this.state.checked.size && (
                  <React.Fragment>
                    <button
                      className="btn btn-primary-o pull-left"
                      style={{ marginRight: '5px' }}
                      onClick={() =>
                        this.multiOperation(
                          'activate',
                          `Aktivovať označené balíky (${
                            this.state.checked.size
                          })?`,
                        )
                      }
                    >
                      {__('Aktivovať')}
                    </button>
                    <button
                      className="btn btn-primary-o pull-left"
                      style={{ marginRight: '5px' }}
                      onClick={() =>
                        this.multiOperation(
                          'deactivate',
                          `Deaktivovať označené balíky (${
                            this.state.checked.size
                          })?`,
                        )
                      }
                    >
                      {__('Dektivovať')}
                    </button>
                    <button
                      className="btn btn-primary-o pull-left"
                      style={{ marginRight: '5px' }}
                      onClick={() =>
                        this.multiOperation(
                          'delete',
                          `Vymazať označené balíky (${
                            this.state.checked.size
                          })?`,
                        )
                      }
                    >
                      {__('Vymazať')}
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div>
              <DataTable
                cols={[
                  <input
                    type="checkbox"
                    checked={
                      this.state.checked.size === otaUpdates.length &&
                      this.state.checked.size > 0
                    }
                    onChange={e => {
                      if (e.target.checked) {
                        this.setState({
                          checked: new Set(otaUpdates.map(({ _id }) => _id)),
                        });
                      } else {
                        this.setState({
                          checked: new Set(),
                        });
                      }
                    }}
                  />,
                  __('Názov'),
                  __('Krajina'),
                  'ECRTYPE',
                  __('Jazyk'),
                  __('CPU verzia'),
                  'ECRHW',
                  'SMHW',
                  __('Verzia ECR'),
                  __('Verzia SM'),
                  __('Stav'),
                  '',
                ]}
                rows={otaUpdates.map(i => {
                  let item = {};

                  for (let c of otaUpdates) {
                    if (c._id === i._id) {
                      item = c;
                      break;
                    }
                  }

                  const isActive = getProp(item, 'active', '');

                  return [
                    <input
                      type="checkbox"
                      checked={this.state.checked.has(item._id)}
                      onChange={() => {
                        this.setState(({ checked }) => {
                          if (checked.has(item._id)) {
                            checked.delete(item._id);
                          } else {
                            checked.add(item._id);
                          }
                          return { checked };
                        });
                      }}
                    />,
                    <Link to={createUrl([this.baseUrl, item._id + '/detail'])}>
                      {getProp(item, 'filename')}
                    </Link>,
                    getProp(item, 'country'),
                    getProp(item, 'ecrtype'),
                    getProp(item, 'lang'),
                    getProp(item, 'cpuversion'),
                    getProp(item, 'ecrhw'),
                    getProp(item, 'smhw'),
                    getProp(item, 'ecr_version', []).join('.'),
                    getProp(item, 'icm_version', []).join('.'),
                    isActive === true ? (
                      <div className="text-success">
                        <Icon name="checkmark" />
                      </div>
                    ) : isActive === false ? (
                      <div className="text-danger">
                        <Icon name="cancel" />
                      </div>
                    ) : null,
                    <Fragment>
                      <a
                        href={createUrl([this.baseUrl, item._id + '/edit'], {
                          ...filter,
                          offset,
                        })}
                        onClick={e => {
                          const url = createUrl(
                            [this.baseUrl, item._id + '/edit'],
                            {
                              ...filter,
                              offset,
                            },
                          );
                          browserHistory.replace(url);
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        className="btn btn-primary-o btn-circle"
                      >
                        <Icon name="edit" />
                      </a>
                      &nbsp;
                      <button
                        onClick={() => this.onClickDeleteOtaUpdate(item)}
                        className="btn btn-danger-o btn-circle"
                        title={__('Zmazať')}
                      >
                        <Icon name="remove" />
                      </button>
                    </Fragment>,
                  ];
                })}
                classNames={[
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  'text-center',
                  'text-right',
                ]}
              />
              <h4
                className={cx('text-center', {
                  hidden: otaUpdates.length > 0,
                })}
              >
                {__('Žiadne aktualizácie')}
              </h4>
            </div>
            <LoadMoreBtn
              nextOffset={nextOffset}
              onClick={() =>
                this.context.router.push(
                  createUrl(this.baseUrl, { ...filter, offset: nextOffset }),
                )
              }
            />
          </Loader>
          <ProtectedContent resource="otaupdates">
            <FloatingActionButton
              linkTo={createUrl([this.baseUrl, 'add'], { ...filter, offset })}
              title={__('Pridať aktualizáciu')}
            />
          </ProtectedContent>
          <div className="cleaner" />
        </Content>
        {sidepanel ? (
          <SidePanel
            onSuccess={onSuccessSidePanel}
            onClose={() => {
              browserHistory.replace(
                createUrl(this.baseUrl, { ...filter, offset }),
              );
            }}
            parameters={{ codeLists, baseUrl: this.baseUrl }}
          >
            {sidepanel}
          </SidePanel>
        ) : null}
      </div>
    );
  }
}

List.contextTypes = {
  router: PropTypes.object,
};

List.defaultProps = {};

export default connect(state => ({}))(List);
